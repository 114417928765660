<template>
  <div>
    <div id="item-detail-page overlow-hidden" v-if="productData && !hasNoProduct">
      <vs-alert
        color="danger"
        title="Error Fetching Product Data"
        :active.sync="error_occured"
      >
        <span>{{ error_msg }}. </span>
        <span>
          <span>Check </span>
          <router-link
            :to="{ name: 'ecommerce-shop' }"
            class="text-inherit underline"
            >All Items</router-link
          >
        </span>
      </vs-alert>
      <vs-breadcrumb
        class="block md:hidden"
        :class="`${this.$store.state.eCommerce.showAlertHeader ? 'mt-20' : ''}`"
        :items="populateBreadcrumbs"
        separator="chevron_right"
      >
      </vs-breadcrumb>
      <vs-breadcrumb
      class="hidden md:block mb-10"
      :items="populateBreadcrumbs"
      separator="chevron_right"
    >
    </vs-breadcrumb>
      <vx-card v-if="productData" :key="productData._id">
        <template slot="no-body">
          <div class="item-content">
            <!-- Item Main Info -->
            <div class="product-details pt-6">
              <div class="vx-row mt-6 px-6 lg:flex-no-wrap">
                <div class="vx-col carousel-container flex items-start justify-center mb-3">
                  <Carousel :images="productData.images"></Carousel>
                </div>

                <!-- Item Content -->
                <div class="vx-col lg:w-3/5 w-full">
                  <div class="w-full flex flex-col justify-center">
                    <!-- STORE LIMITATION -->
                      <div>
                        <vs-card :key="limitation._id" v-for="limitation in limitationsList">
                          <p v-if="limitation.isAboveMax" class="text-base text-danger">
                            {{ limitation.display_message_max }}
                          </p>
                          <p v-if="limitation.isBelowMin" class="text-base text-danger">
                            {{ limitation.display_message_min }}
                          </p>
                          <p v-if="limitation.isBellowAmount" class="text-base text-danger">
                            {{ limitation.display_purchase_message_min }}
                          </p>
                        </vs-card>
                      </div>
                    <!-- STORE LIMITATION -->
                    <div>
                      <p v-if="productData.inventory.requiredQuantity > 1" class="text-base">
                        Disclaimer: This product has a minimum order quantity of {{ productData.inventory.requiredQuantity }}
                      </p>
                    </div>
                    <div
                      class="text-lg opacity-75"
                      :class="{ hidden: !isLeadForm }"
                    >
                      {{ productData.productType }}
                    </div>
                    <div
                      class="text-lg opacity-75 text-primary"
                      v-if="productData.brand && productData.brand.length"
                    >
                      {{ productData.brand[0].brandName || '---' }}
                    </div>
                    <div
                      class="text-lg lg:text-2xl md:text-2xl font-semibold cursor-pointer text-pd-green"
                    >
                      {{ productData.name }}
                      <span v-if="isLeadForm"
                        >- {{ productData.productType }}</span
                      >
                    </div>
                    <div
                      class="lg:text-2xl md:text-2xl font-semibold my-1 text-pd-green"
                      v-if="isLeadForm"
                    >
                      ${{ hasDiscount(productData._id) ? hasDiscount(productData._id) : productData.price | formatDecimals }}
                    </div>

                    <!-- price per measurement -->
                    <div
                      class="price-per-measurement text-primary2 mt-2 mb-2"
                      v-if="productData.isPricePerMeasurementHidden"
                    >
                      ${{ measurementPrice }}
                    </div>
                    <!-- end of price per measurement -->

                    <div v-html="productData.shortDescription"
                      class="text-sm opacity-75 my-1 product-quick-view-desc v-html-list-type-disc-dec"  v-if="!isLeadForm" />

                    <bundle-products-form
                      :forms="options"
                      :onSave="onSave"
                      @insertPatient="insertPatient"
                      v-if="isProductBundle"
                      :productData="productData"
                      @validateBundleFields="validateBundleFields"
                    />
                    <patient-form
                      :forms="forms"
                      :onSave="onSave"
                      @insertPatient="insertPatient"
                      v-if="isProductScript"
                      :directionForUse="productData.directionForUse"
                    />
                    <lead-form
                      v-if="isLeadForm"
                      :productInfo="productData"
                      :onSave="onSave"
                      :isLoading="isLoading"
                      @sendEnquiry="handleSendEnquiry"
                    />
                    <patient-tc
                      :onSave="onSave"
                      @insertPatient="insertTC"
                      :link="productData.tcLink"
                      v-if="productData.tc"
                    />
                    <div
                      v-if="!productData.isBundle && !isLeadForm"
                      class="text-2xl font-semibold text-primary mt-1"
                    >
                      ${{ hasDiscount(productData._id) ? hasDiscount(productData._id) : productData.price | formatDecimals}}
                    </div>
                    <div
                      class="rrp-price mt-4 text-pd-green"
                      v-if="!productData.isRRPHidden"
                    >
                      RRP <span :class="productData.isCompareAtPriceToggled && 'line-through'">${{ productData.compareAtPrice | formatDecimals }}</span>
                    </div>
                    <div
                      class="rrp-price mt-1 text-pd-green"
                      v-if="!productData.isListPriceHidden"
                    >
                      List Price <span :class="productData.isListPriceToggled && 'line-through'">${{ productData.listPrice | formatDecimals }}</span>
                    </div>
                  </div>

                  <div
                    v-if="!isLeadForm"
                  >
                    <div class="bg-white fixed left-0 md:left-none lg:left-none bottom-0 w-full lg:w-auto md:w-auto lg:bottom-none md:bottom-none lg:relative md:relative flex flex-row justify-between lg:justify-start md:justify-start cart-button z-10">
                      <template v-if="isInCartTest(productData._id) && !isProductBundle && !isProductScript">
                        
                        <div class="w-full lg:w-auto mx-4 md:mx-0">

                          <small class="block font-medium text-red-600 text-center lg:text-left mb-1 lg:mb-0 lg:mt-3 min-error-text pt-2 pb-1 lg:pt-0 lg:pb-0"> {{minMaxErrorText}} </small>

                          <div
                            v-if="((productData.canBuy && productData.isMatchS4Medication) || superAdminCanAddToCart) && productData.isAvailable"
                            class="w-full flex flex-row rounded-lg py-1 shop-button lg:mt-4 items-center"
                          >
                            <button
                              class="w-2/12 flex justify-center items-center text-2xl cursor-pointer font-bold sm:font-normal"
                              style="min-width: 33px; height: 33px;" 
                              @click="
                                decrease(
                                  productData.quantity,
                                  productData,
                                  productData.inventory.requiredQuantity
                                )
                              "
                            > &minus;
                            </button>

                            <input
                                name="qty"
                                type="number"
                                min="item.inventory.requiredQuantity"
                                max="item.inventory.maxQuantity"
                                class="w-full text-xl font-normal rounded-sm"
                                ref="qtyInput"
                                :value="cartProduct(productData._id)"
                                @input="updateCartProduct($event, productData)"
                                @blur="minMaxErrorText = ''"
                              />


                            <button
                              class="w-2/12 flex justify-center items-center text-2xl cursor-pointer px-3 font-bold sm:font-normal"
                              style="min-width: 33px; height: 33px;" 
                              @click="increase(quantity, productData)"
                            >
                              &plus;
                            </button>

                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="w-full">
                          <vs-row vs-w="12" class="w-full" :class="isProductScript ? 'productScriptRow' : ''" v-if="((productData.canBuy && productData.isMatchS4Medication) || superAdminCanAddToCart) && productData.isAvailable">
                            <vs-col
                              :class="{
                                'md:w-auto': !isProductScript && !isProductBundle,
                                'script-button md:vs-xs-8 vs-lg-12': isProductScript,
                                'w-full lg:w-9/12': isProductBundle,
                              }">
                              <div
                                class="flex flex-wrap items-start lg:mb-4 w-full md:w-auto h-full lg:mt-2"
                                :class="isProductScript ? 'w-full' : 'w-1/2'"
                              >
                                <!-- Add To Cart Button -->
                                <vs-button
                                  class="item-view-secondary-action-btn bg-primary py-4 md:py-3 px-12 mt-2 lg:rounded-lg items-center justify-center text-white cursor-pointer w-full md:h-auto h-full text-base md:text-lg"
                                  :disabled="!validateForm"
                                  @click="addToCart(productData)"
                                >
                                  Add to Cart
                                </vs-button>
                              </div>
                            </vs-col>
                          </vs-row>
                        </div>
                      </template>
                    </div>

                    <div
                      v-if="!productData.canBuy && !superAdminCanAddToCart"
                      class="flex flex-wrap items-start lg:mb-4 w-full lg:w-auto mt-4"
                    >
                      <vs-button
                        class="item-view-secondary-action-btn bg-primary py-4 md:py-3 px-12 lg:rounded-lg items-center justify-center text-white cursor-pointer w-full lg:w-auto md:h-auto h-full text-base md:text-lg"
                        v-if="productData.supplier.supplierOnboardingLink && productData.isMatchS4Medication"
                        @click="learnMore(productData.supplier.supplierOnboardingLink)"
                      >
                        Learn More
                      </vs-button>

                      <vs-button
                        class="item-view-secondary-action-btn bg-primary py-4 md:py-3 px-12 lg:rounded-lg items-center justify-center text-white cursor-pointer w-full lg:w-auto md:h-auto h-full text-base md:text-lg"
                        @click="popupActive = true"
                        v-else
                      >
                        Contact Fresh
                      </vs-button>
                    </div>

                    <div
                      v-if="productData.canBuy && !superAdminCanAddToCart && !productData.isMatchS4Medication"
                      class="flex flex-wrap items-start lg:mb-4 w-full lg:w-auto mt-4"
                    >
                      <vs-button
                        class="item-view-secondary-action-btn bg-primary py-4 md:py-3 px-12 lg:rounded-lg items-center justify-center text-white cursor-pointer w-full lg:w-auto md:h-auto h-full text-base md:text-lg"
                        @click="popupActive = true"
                      >
                        Contact Fresh
                      </vs-button>
                    </div>

                    <div
                      v-if="!productData.isAvailable && productData.canBuy && productData.isMatchS4Medication"
                      class="flex flex-wrap items-start lg:mb-4 w-full lg:w-auto md:w-auto mt-4"
                    >
                      <vs-button
                        class="item-view-secondary-action-btn bg-gray py-5 lg:py-3 md:py-3 px-4 rounded w-full lg:w-auto items-center justify-center text-white cursor-pointer"
                      >
                        Out of Stock
                      </vs-button>
                    </div>

                  </div>
                  <div class="rounded-lg custom-primary-bg-opaque p-4 text-primary2 text-sm mt-4 v-html-list-type-disc-dec" v-if="productData.promotionalDescription" v-html="productData.promotionalDescription"/>
                </div>
              </div>
              <div class="product-desc-and-doc">
                <div v-if="productData.description && productData.description.length">
                  <vs-divider></vs-divider>
                  <div class="px-6 pt-4 pb-8">
                  <h5
                    class="product-desc-label font-semibold text-pd-green md:mb-0 mb-3"
                  >
                    Description
                  </h5>
                  <div
                    class="product-description md:ml-auto v-html-list-type-disc-dec"
                    v-html="productData.description"
                    style="font-size: 14px; margin-top: 4px;"
                  />
                  </div>
                </div>
                <div v-if="hasDocuments(productData)">
                  <vs-divider></vs-divider>
                  <div class="p-6">
                    <h5 class="font-semibold text-pd-green md:mb-0 mb-3">
                      Documents
                    </h5>
                    <div
                      class="grid gap-4 md:grid-cols-2 product-doc-label md:ml-auto"
                    >
                      <div
                        class="document-label p-2 text-center"
                        v-for="(doc, index) of productData.documents"
                        :key="index"
                      >
                        <a
                          :href="doc.image"
                          target="_blank"
                          class="text-pd-green"
                        >
                          {{ doc.fileName }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </template>
      </vx-card>

      <!-- popup -->
      <vs-popup class="holamundo" title="Supplier Account Required" :active.sync="popupActive">
        <div class="flex flex-no-wrap">
          <div class="w-1/6 flex items-center justify-center">
            <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
          </div>
          <div class="w-5/6">
            <ContactFresh />
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <vs-button class="mr-4 mb-4" @click="popupActive = false">
            I understand
          </vs-button>
        </div>
      </vs-popup>
    </div>
    <ProductDetailsErrorPageVue urlPath="shop" v-if="hasNoProduct" />
  </div>
</template>

<script>
// CSS
import "swiper/dist/css/swiper.min.css";

// Lib
import StarRating from "vue-star-rating";
import { mapActions } from "vuex";

// Components
import RelatedProducts from "../../components/order-center/RelatedProducts";
import Carousel from "./Carousel";
import PatientForm from "./PatientForm";
import PatientTc from "./PatientTC";
import LeadForm from "./LeadForm.vue";
import ContactFresh from "../../components/ContactFresh";
import BundleProductsForm from "./BundleProductsForm.vue";
import ProductDetailsErrorPageVue from './ProductDetailsErrorPage.vue';


// Mixins
import StoreHelper from '../../../mixins/Store.vue'


// Utils
import {
  trackEcommerceEvent, 
  formatPriceValue,
  ADD_TO_CART_EVENT, 
  VIEW_ITEM_EVENT,
  getCurrency
} from '../../../utils/gtag'

import { quantity } from "echarts/lib/util/number";

export default {
  name: "ProductDetail",
  mixins: [StoreHelper],
  components: {
    Carousel,
    RelatedProducts,
    StarRating,
    PatientForm,
    PatientTc,
    ContactFresh,
    BundleProductsForm,
    LeadForm,
    ProductDetailsErrorPageVue,
  },
  watch: {
    "$route.params.id": function (id) {
      this.fetch_item_details(id);
    },
    "$store.state.clinicId": async function () {
      await this.getClinicDetails()
      await this.fetch_item_details(this.$route.params.id);
    },
  },
  data() {
    return {
      popupActive: false,
      quantity: 1,
      productData: null,
      availableQuantity: 0,
      error_occured: false,
      error_msg: "",
      minMaxErrorText: '',
      // Below is data which is common in any item
      // Algolia's dataSet don't provide this kind of data. So, here's dummy data for demo
      available_item_colors: [
        "#7367F0",
        "#28C76F",
        "#EA5455",
        "#FF9F43",
        "#1E1E1E",
      ],
      opt_color: "#7367F0",
      is_hearted: false,
      relatedProducts: [],
      forms: {
        firstName: null,
        lastName: null,
        address: null,
        suburb: null,
        state: null,
        zipcode: null,
        dob: null,
        allergies: null,
        isForPatient: true,
      },
      resetForm: {
        firstName: null,
        lastName: null,
        address: null,
        suburb: null,
        state: null,
        zipcode: null,
        dob: null,
        allergies: null,
        isForPatient: true,
      },
      options: {},
      onSave: false,
      isLoading: false,
      alertBanner: [],
      learnMorePopupActive: false,
      selectedBanner: {},
      isMobileView: null,
      validBundleForm: false,
      canAdd: false,
      hasNoProduct: false,
      discounts: [],
      clinic: null
    };
  },
  computed: {
    populateBreadcrumbs () {
      const userTypeAdmin = this.$store.state.AppActiveUser.userType
      /** get base url */
      let owner = this.$route.path.split('/')[1]

      if (userTypeAdmin && userTypeAdmin === "superAdmin")
        owner = 'super-admin/store'

      const searchUrl = `/${owner}/shop`;

      const from = this.$route.query.from
      const fromPath = this.$route.query.fromPath

      if (from && fromPath) {
        return [
          { title: 'Home', url: searchUrl },
          { title: `${from}`, url: fromPath },
          { title: `${this.productData.name}`, active: true },
        ]
      }
      
      return [
        { title: 'Home', url: searchUrl },
        { title: `${this.productData.name}`, active: true },
      ]
    },
    measurementPrice() {
      const price = (this.productData.price / this.productData.pricePerMeasurement.price).toFixed(2)
      return `${price} ${this.productData.pricePerMeasurement.unit}`
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    },
    superAdminCanAddToCart() {
      return this.isLoggedInSuperAdmin && !!this.canAdd
    },
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.learnMorePopupActive;
      }
      return true
    },
    item_qty() {
      const item = this.$store.getters["eCommerce/getCartItem"](
        this.productData.objectID
      );
      return Object.keys(item).length ? item.quantity : 1;
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    itemColor() {
      return (obj) => {
        let style_obj = {};

        obj.style.forEach((p) => {
          style_obj[p] = obj.color;
        });

        return style_obj;
      };
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    validateForm() {
      return !this.errors.any();
    },
    isProductScript() {
      return this.productData && this.productData.type === "script";
    },
    isProductBundle() {
      return this.productData && this.productData.type === "bundle";
    },
    isLeadForm() {
      return this.productData && this.productData.type === "lead";
    },
    getCartInfo() {
      return (itemId) => this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    cartProduct() {
      return (itemId) => this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    }
  },
  methods: {
    ...mapActions("ecommerce", [
      "fetchProductDetail",
      "getCartItemsFromApi",
      "sendEnquiry",
    ]),
    ...mapActions("storeAlertNotification", ["fetchStoreAlertsBannerByProductId"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),

    learnMore(url){
      window.open(url)
    },
    async fetchCanAddSettingKey() {
      try {
        const { data } = await this.getSettingsByKey("superAdminCanAdd")

        if (data.data) this.canAdd = data.data.value === "yes"
      } catch (err) {
        console.log(' ----> error fetching can-add setting key ')
        console.log(err)
      }
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
      this.options = {};

      let resetOptions = {};
      Object.keys(this.forms).forEach((name) => (resetOptions[name] = null));
    },
    updateCartProduct(e, item) {
      const carItem = this.getCartInfo(item._id)
      const value   = parseInt(e.target.value)
      const minQty  = item.inventory.requiredQuantity;
      const maxQty  = item.inventory.maxQuantity;
      let qty = value


      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {

          if (value < minQty || value < 1 || isNaN(value) || value == '') {
            this.minMaxErrorText = `Minimum quantity is ${minQty}.`
            e.target.value = minQty;
            qty = minQty
          } else if (maxQty > 0 && value > maxQty) {
            this.minMaxErrorText = `Maximum quantity is ${maxQty}.`
            e.target.value = maxQty;
            qty = maxQty
          } else {
            this.minMaxErrorText = ''
            e.target.value = qty;
          }

          let prod = {
            data: item,
            qty: qty
          };

          this.$store.commit("eCommerce/UPDATE_ITEM_QUANTITY_TEST", prod);

          this.$store.dispatch("eCommerce/updateItemQuantity", {
            item: carItem

          }).then((response) => {

            if (response.message !== 'success') {
              this.$vs.notify({
                color: 'danger',
                title: `Product Not Updated`,
                text: `We are having issues updating your cart. Please try again.`
              });
            }
          });

     }, 1000);

    },
    increase(quantity, data) {
      let cartItem = this.getCartInfo(data._id);
      let cartQty = cartItem.quantity

      if (!this.isProductScript) {
        this.quantity = cartQty + 1;
        this.selectedChangeQuantity(this.quantity, data, 'increment');
      }
    },

    decrease(quantity, data, requiredQuantity) {
      let cartItem = this.getCartInfo(data._id);
      let cartQty = cartItem.quantity

      if (!this.isProductScript) {
        if (cartQty >= requiredQuantity) {
          this.quantity = cartQty - 1;
          this.selectedChangeQuantity(this.quantity, data, 'decrement');

        } else {
          this.removeItemFromCart(cartItem)
        }
      } else {
          this.$vs.notify({
              title: "Error",
              text: `Cannot modify script product`,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            return false;
        }
    },

    selectedChangeQuantity(quantity, data, type) {
      let cartItem = this.getCartInfo(data._id);
      let cartQty = cartItem.quantity

      if (
        data.inventory.maxQuantity !== null &&
        data.inventory.maxQuantity !== 0
        ) {
        if (quantity > data.inventory.maxQuantity) {
          this.$vs.notify({
            title: "Failed",
            text: "You have reached the maximum order quantity.",
            color: "danger",
          });
          return false;
        }
      }

      if (quantity  < data.inventory.requiredQuantity) {
        this.removeItemFromCart(cartItem)
        return false;
      } else {
        this.errors.removeById(data._id);
        this.additemInCart(data, type)
      }
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    additemInCart(data, type) {
      let item = {data: data, type: type}
      let messageType = type === 'decrement' ? 'removed from your cart' : 'added to your cart'

      this.$vs.loading();
      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        this.forms = { ...this.resetForm };
        let resetOptions = {};
        Object.keys(this.forms).forEach((name) => (resetOptions[name] = null));
        this.options = {};
        this.getCartItemsFromApi(sessionStorage.getItem("doctorClinicSelectedClinic"))
        if(response.status === 200 && response.data.message === 'success') {
          this.$vs.notify({
            color: 'success',
            title: `Product ${messageType}`,
            text: `Product successfully ${messageType}.`
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }
        this.$vs.loading.close();
      }
      );
    },
    addToCart(item) {
      this.quantity = 1;
      if (this.$store.state.clinicId === null) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Please Select a clinic.",
          color: "danger",
        });
        return false;
      }
      trackEcommerceEvent(ADD_TO_CART_EVENT, {
        currency: getCurrency(),
        value: formatPriceValue(this.productData.price),
        items: [
          {
            item_id: item._id,
            item_name: item.name,
            price: formatPriceValue(item.price),
            quantity: this.quantity
          }
        ]
      });

       if (this.isProductScript || item.tc || this.isProductBundle) {
          this.onSave = true;
          setTimeout(() => {
            this.onSave = false;
          }, 500);
        } else {
          this.toggleItemInCart(item);
        }
    },
    insertPatient(isPatient = true) {
      if (this.productData.type === "bundle") {
        const bundleItems = Object.keys(this.options).map((key) => {
          const items = this.productData.options.find((item) => item.value === key);

          return {
            name: key,
            quantity: Number(this.options[key]),
            isFree: items.isFree || false,
            supplierCostPrice: items.supplierCostPrice || null,
            productStoreId: items.productStoreId || null,
            KAPrice: items.itemDetails && items.itemDetails.KAPrice || 320,
            type: items.itemDetails && items.itemDetails.type,
            sageItemId: items.itemDetails && items.itemDetails.sageItemId,
            supplierId: items.itemDetails && items.itemDetails.inventory.managedBy
          }
        });
        // Add free items to bundleItems
        const freeItems = this.productData.options.filter((item) => item.isFree);
        if (freeItems.length) {
          freeItems.forEach((item) => {
            bundleItems.push({
              name: item.value,
              quantity: item.quantity,
              isFree: item.isFree,
              supplierCostPrice: item.supplierCostPrice,
              productStoreId: item.productStoreId,
              KAPrice: item.itemDetails && item.itemDetails.KAPrice || 320,
              type: item.itemDetails && item.itemDetails.type,
              sageItemId: item.itemDetails && item.itemDetails.sageItemId,
              supplierId: item.itemDetails && item.itemDetails.inventory.managedBy,
            })
          });
        }
        this.toggleItemInCart({
          ...this.productData,
          bundleItems: bundleItems,
        });
      }
      if (isPatient) {
        this.toggleItemInCart({ ...this.productData, patient: this.forms })
      }
    },
    insertTC() {
        this.toggleItemInCart({ ...this.productData })
    },
    async toggleItemInCart(item) {
      let productItem = item
      let minQty = 1;
      let type = "increment"

      if(productItem.inventory.requiredQuantity > 1) {
        minQty = productItem.inventory.requiredQuantity
      }
      let data = {data: productItem, type: type, minQty: minQty}
      let isValid = await this.$validator.validateAll("basic");

      if (item.tc && !this.isProductScript) {
        isValid = true;
      }
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);

      if (this.quantity == 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      item.selectedQuantity = this.quantity;
      item.shipping.shippingFee = item.supplier.deliveryDetails
        ? item.supplier.deliveryDetails.deliveryFee
        : 0;

      const totalCartAndSelectedQty = cartQuantity + item.selectedQuantity;
      if (
        item.inventory.maxQuantity !== null &&
        item.inventory.maxQuantity !== 0
      ) {
        if (
          item.selectedQuantity > item.inventory.maxQuantity ||
          totalCartAndSelectedQty > item.inventory.maxQuantity
        ) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }
  
      this.$vs.loading();
      data.data.shipping.shippingFee = data.data.supplier.deliveryDetails ? data.data.supplier.deliveryDetails.deliveryFee : 0;
      this.$store.dispatch("eCommerce/additemInCart", data).then((response) => {
        this.getCartItemsFromApi(),
        this.$vs.loading.close();
        this.$vs.notify({
          color: 'success',
          title: 'Product added',
          text: 'Product was successfully added to your cart.'
        })
      }).catch((err) => {
        console.log("ERR: ", err);
        this.$vs.loading.close();
        const supplierErrMsg = "Sorry! You need to setup a supplier account to purchase this product."
        if (err.data && err.data.message && err.data.message === supplierErrMsg) {
          this.productData.canBuy = false;
          this.$vs.notify({
            color: 'danger',
            title: 'Add to cart failed',
            text: supplierErrMsg,
            time: 5000,
          });
        }
      });
    },
    fetch_item_details(id) {
      this.$vs.loading();

      this.fetchProductDetail(this.$route.params.id).then(async (response) => {

        await this.fetchDiscountList().then((response) => {
          this.discounts = response.data.data[0].productDiscounts;
        });

        this.productData = response.data.data.product;

        trackEcommerceEvent(VIEW_ITEM_EVENT, {
          currency: getCurrency(),
          value: formatPriceValue(this.productData.price),
          items: [
            {
              item_id: this.productData._id,
              item_name: this.productData.name,
              price: formatPriceValue(this.productData.price),
              quantity: 1
            }
          ]
        });

        //sort images from default image
        this.productData.images = [
            ...response.data.data.product.images.filter(c => c.useAsDefault === true),
            ...response.data.data.product.images.filter(c => c.useAsDefault === false)
        ];

        if (
          this.productData.inventory.requiredQuantity !== undefined &&
          this.productData.inventory.requiredQuantity !== 0
        ) {
          this.quantity = this.productData.inventory.requiredQuantity;
        } else {
          this.quantity = 1;
          this.productData.inventory.requiredQuantity = 1;
        }
        this.availableQuantity = response.data.data.product.quantity;
        this.relatedProducts = response.data.data.relatedProducts.docs;
        if(response.data.data.product.hideStatus){
          if(response.data.data.product.ifHiddenThenVisibleToClinics){
            const clinicsHasAccess = response.data.data.product.ifHiddenThenVisibleToClinics.filter((id) => this.clinic.data.data._id === id)
            if(clinicsHasAccess.length > 0){
              this.hasNoProduct = false;
            }
            else{
              this.hasNoProduct = true;
            }
          }
          else {
            this.hasNoProduct = true;
          }
        }
        else {
          this.hasNoProduct = false;
        }
        this.$vs.loading.close();

      }).catch(err => {
        console.error(err)
        this.hasNoProduct = true;
        this.$vs.loading.close();
      });
    },
    hasDiscount(productId) {
      const clinicData = this.clinic

      if(clinicData && clinicData.data && clinicData.data.data && clinicData.data.data.clinicDiscounts.length > 0) {
        for(let discount of this.discounts) {
          if(clinicData.data.data.clinicDiscounts.includes(discount._id)) {
            for(let productDiscount of discount.productDiscounts)
              if (productDiscount.productStoreId === productId && productDiscount.isActive) {
                return Number(productDiscount.adjustedPrice).toFixed(2);
              }
          }
        }
      }

      return null;

    },
    handleSelectBanner(details) {
      this.selectedBanner = {...details}
      this.learnMorePopupActive = true;
    },
    checkItemQuantity(quantity, data) {
      if (this.quantity < data.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg:
            "Minimum order quantity of " +
            data.name +
            " is " +
            data.inventory.requiredQuantity,
          id: data._id,
        });
      } else {
        this.errors.remove("MinimumQuantityLimit");
      }
    },
    hasDocuments(doc) {
      if (doc.documents && doc.documents.length) {
        return true;
      }
      return false;
    },
    handleSendEnquiry(data) {
      if (this.productData) {
        this.onSave = true;
        this.isLoading = true;
        this.sendEnquiry({
          email: this.productData.supplier.email[0],
          form: data,
          product: this.productData.name || "",
        })
          .then(() => {
            this.onSave = false;
            this.isLoading = false;

            this.$vs.notify({
              title: "Success",
              text: "Email sent!",
              color: "success",
            });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$vs.notify({
              title: "Failed",
              text: err.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            console.log(err);
          });
      }
    },
    validateBundleFields(data) {
      this.validBundleForm = data
    },
    async getClinicDetails() {
      try {
        this.clinic = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
      } catch (error) {
        console.log(`Error fetching clinic:: ${error}`)
      }
    },
  },

  async created() {
    await this.getClinicDetails()
    await this.fetch_item_details(this.$route.params.id);

    if (this.isLoggedInSuperAdmin) this.fetchCanAddSettingKey()
  },

  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();

  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

@mixin light-green-bg-with-border-radius {
  background: #3cc9b230;
  border-radius: 5px;
}
.custom-primary-bg-opaque {
  background-color: rgba(132, 205, 184, 0.2);
}

#item-detail-page {
  .increment-buttons {
    height: 40px;
    width: 100px;
    @include light-green-bg-with-border-radius;
  }

  button.bg-gray {
    background-color: #a0a0a0 !important;
  }

  .color-radio {
    height: 2.28rem;
    width: 2.28rem;

    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1),
          0 5px 12px 0 rgba(0, 0, 0, 0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(0.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }
  }

  .document-label {
    border: 1px solid #3e695c;
    border-radius: 5px;
  }
  .product-doc-label {
    width: 90%;
  }
  .product-description {
    ul,
    ol {
      padding-left: 2.5rem;
    }
    ul {
      list-style-type: initial;
    }
    ol {
      list-style-type: auto;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .text-pd-green {
    color: #3e695c;
  }
}
.v-html-list-type-disc-dec ol > li {
  list-style-type: decimal;
  margin-left: 1.5rem;
}
.v-html-list-type-disc-dec ul > li {
  list-style-type: disc;
  margin-left: 1.5rem;
}
.carousel-container{
  width: 100%;
}
@media (min-width: 992px){
  .carousel-container{
    width: 475px;
  }
}

@media (max-width: 767px) {
  .vs-button {
    border-radius: 0 !important;
  }

  .increment-buttons {
    height: auto !important;
    border-radius: 0 !important;
  }

  .vue-back-to-top {
    bottom: 10% !important;
  }
  .productScriptRow{
    width: 100%!important
  }
}


.swiperBox {
  position: relative;
  z-index: 0;
}

.shop-button{
  z-index: 9999;
  background-color: #0E4333;

  button{
    color: #fff;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    transition: transform 200ms linear;

    i {
      font-size: 1.2rem;
    }

    &:hover{
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:active {
      transform: scale(1.1);
      transform-origin: center;
    }
  }

  input[type=number] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none !important;
    background-color: #fff;
    text-align: center;
    border-radius: .2rem;

      &:focus {
        box-shadow: none;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
}

.cart-button {
  button {
    z-index: 999;
  }
}

@media (min-width: 768px) {
  .script-button {
    width: 66.6667% !important;
  }
}

.learnMorePopup .vs-popup {
  width: 50%;
}

</style>
