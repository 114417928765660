<template>
  <div>
    <div class="text-2xl font-semibold text-primary my-1">
      ${{ productData.price | formatDecimals }}
    </div>
    <form autocomplete="off" @submit.prevent class="py-5">
      <h5 class="py-2">{{productData.productTypeHeading}}</h5>
      <p class="mb-4">{{productData.productTypeDescription}}</p>

      <div v-for="({ name, isFree }, index) in inputs" v-bind:key="name + index" class="w-full lg:w-9/12">
          <vs-input
            v-if="!isFree || isFree === undefined"
            :success="!errors.first(`bulkItems.${name}`)"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first(`bulkItems.${name}`) ? true : false"
            :name="name"
            :data-vv-as="name"
            :label="name"
            class="w-full custom-field"
            :danger-text="errors.first(`bulkItems.${name}`)"
            v-model="forms[name]"
            data-vv-scope="bulkItems"
            v-validate="{
              numeric: true,
              min_value: 0,
            }"
            autocomplete
            placeholder="Enter Amount"
            icon-no-border
          />
      </div>
      
      <p
        v-if="count && count < productData.minQty"
        class="text-base text-danger"
      >
        {{ productData.minErrorMessage }}
      </p>
      <p
        v-if="count && count > productData.maxQty"
        class="text-base text-danger"
      >
        {{ productData.maxErrorMessage }}
      </p>
    </form>
    <div v-if="hasFreeItems" class="text-sm pb-4">
      <p class="italic">Free item includes:</p>
      <div 
        v-for="({ name, itemDetails, quantity, productStoreId }, index) in freeItems" 
        v-bind:key="name + index" 
        class="w-full pb-2 cursor-pointer"
        @click="goTo(productStoreId)"
        >
        <div class="inline-flex items-center gap-2 border border-solid border-gray-500 rounded px-4">
        <p>{{ quantity }} x </p>
        <div class="flex items-center gap-1">
          <img
              v-if="isImageExists(itemDetails)"
              :src="getImage(itemDetails)"
              alt="free"
              class="w-8 h-8" />
          <p>{{ name }}</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import _ from "lodash";
export default {
  components: {
    Datepicker,
  },
  name: "BundleProductsForm",
  props: ["forms", "onSave", "insertPatient", "productData"],
  inject: ['getRoleRouteName'],
  data() {
    return {
      inputs: [],
      count: null,
      freeItems: [],
    };
  },
  methods: {
    isImageExists(data) {
      if (data.images && data.images.length) {
        return true;
      }
      return false;
    },
    getImage(data) {
      if (data.images && data.images.length) {
        return data.images[0].image;
      }
      return null;
    },
    goTo(id) {
      this.$router.push({
        name: this.getRoleRouteName("ProductDetail"),
        params: { id },
      });
    },
  },
  watch: {
    'forms': {
      deep: true,
      handler(value) {
        let objLen = Object.keys(value).length;
        const optionsLen = this.productData.options.length;
        
        const isDisabled = Object.keys(value).filter(
          (k) => !value[k] || value[k] === ""
        );

        if(objLen <= 0 ) {
          this.$emit("validateBundleFields", false);
          
        } else if(objLen >= optionsLen) {
          let disabledFieldsCount = isDisabled.length <= 0
          this.$emit("validateBundleFields", disabledFieldsCount);
        }
      }
    },
    onSave: async function (val) {
      if (val) {
        let isValid = await this.$validator.validateAll("bulkItems");
        const count = _.sum(
          Object.keys(this.forms).map((name) => parseInt(this.forms[name]))
        );
        this.count = count;
        if (
          this.count < this.productData.minQty ||
          this.count > this.productData.maxQty
        ) {
          isValid = false;
        }
        if (isValid) {
          this.$emit("insertPatient", false);
        }
      }
    },
    "$store.state.clinicId": function () {
      this.count = null
    }
  },
  beforeMount() {
    this.inputs = this.productData.options.map((option) => ({
      name: option.value,
      isFree: option.isFree || false,
      supplierCostPrice: option.supplierCostPrice || null,
      productStoreId: option.productStoreId || null,
      itemDetails: option.itemDetails || {},
      quantity: option.quantity || 0,
    }));
    this.freeItems = this.inputs.filter(item => item.isFree);
  },
  computed: {
    hasFreeItems() {
      return this.inputs.filter(item => item.isFree).length > 0
    }
  },
};
</script>

<style lang="scss">
.custom-field label {
  display: block;
  padding: 5px 0 !important;
  font-size: 12px;
}
.bg-color-error {
  background: rgba(234, 84, 85, 0.14);
  color: rgba(234, 84, 85, 1);
}
.bg-color-done {
  background: rgba(40, 199, 111, 0.14);
  color: rgba(40, 199, 111, 1);
}
.is-danger {
  border: solid 1px rgba(234, 84, 85, 1) !important;
}
.is-true {
  border: solid 1px rgba(40, 199, 111, 1) !important;
}


.vs {
  &-input {
    &--input.normal {
      font-size: 16px !important;
    }
    &--label {
      font-size: 1rem !important;
    }
  }
}
</style>
